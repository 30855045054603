import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { push } from 'connected-react-router';
import { Container, Card, Table, Button, Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExclamationTriangle, faSearch, faEdit, faTrash, faBroom, faFilter, faDownload, faInfoCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import ActionIcon from 'src/components/general/ActionIcon';
import axios from 'axios';
import { questionAnnulConfirm } from 'src/utils/label';
import { dateNeutralFormatedToShowARG, getClearObject, fundFormsReadyToDisplay, getFundRequestTypeValueToShow, stateFormsReadyToDisplay, getFundRequestStateValueToShow, formatterPeso} from 'src/utils/utils';
import { getUserPermissionsFundRequests } from 'src/redux/login/loginReducer';
import { FUND_REQUESTS_NEW, FUND_REQUESTS_EDIT, FUND_REQUESTS_DETAILS } from 'src/utils/constants';
import { tryListFundRequests, tryDeleteFundRequests, tryGetTypeListFundRequests, tryGetStateListFundRequests } from 'src/redux/fundRequests/fundRequestsActionCreator';
import { clearListFundRequestsData, setFundRequestsToDetail, setFundRequestsToEdit } from 'src/redux/fundRequests/fundRequestsActions'
import { getFundRequestsListIsFetching, getFundRequestsListData, getFundRequestTypesData , getFundRequestStatesData} from 'src/redux/fundRequests/fundRequestsReducer';
import RCPagination from 'src/components/common/RCPagination';
import AppLoading from 'src/components/common/AppLoading';
import { getReportpassiveWasteIsFetching } from "src/redux/reports/reportsReducer";
import { tryGetReportFundRequestById } from "src/redux/reports/reportsActionCreactor";
import { getAdministrativeServiceListAllData, getListAllServiceAdministrativeByUserIsFetching, getListIdsServiceAdministrativeByUser } from "src/redux/administrativeService/administrativeServiceReducer";
import { getGlobalDataSelectedPeriod } from 'src/redux/globalData/globalDataReducer.js';
import { getServicesByExerciseIdData, getServicesByExerciseIdIsFetching } from 'src/redux/exercise/exerciseReducer.js';
import { tryListAllAdministrativeServices, tryListAllServiceAdministrativeByUser } from 'src/redux/administrativeService/administrativeServiceActionCreator';
import { administrativeServiceClose, AdministrativeServiceData, VALIDATE_ACTION_TYPE } from 'src/utils/administrativeServiceUtils.js';
import { getEmail } from 'src/redux/login/loginReducer';
import { getUserListData } from 'src/redux/user/userReducer';

const FundRequestsListPage = () => {
    const dispatch = useDispatch();
    const { handleSubmit, register, reset } = useForm();
    const reportIsFetching = useSelector(state => getReportpassiveWasteIsFetching(state));

    // Permissions
    const fundRequestsPermissions = useSelector(state => getUserPermissionsFundRequests(state));
    const listIdsServiceAdministrative = useSelector(state => getListIdsServiceAdministrativeByUser(state));
    const userAdmServIsFetching = useSelector(state => getListAllServiceAdministrativeByUserIsFetching(state));

    // Fund Requests
    const fundRequestData = useSelector(state => getFundRequestsListData(state));
    const fundRequestsListIsFetching = useSelector(state => getFundRequestsListIsFetching(state));
    const selectedPeriod = useSelector(state => getGlobalDataSelectedPeriod(state));

    const records = fundRequestData?.records;
    const totalCount = fundRequestData?.metadata?.filteredCount;
    const pageNumber = fundRequestData?.metadata?.page;
    const [filterObject, setFilterObject] = useState({});
    const fundRequestTypes = useSelector(state => getFundRequestTypesData(state));
    const fundRequestStates = useSelector(state => getFundRequestStatesData(state));

	const creditLabelsRefContainer = useRef(null);
	const colSpanCellsNumber = creditLabelsRefContainer?.current?.cells.length;

    const CancelToken = axios.CancelToken;
    let cancelSource = CancelToken.source();
    let newFilterObject;

	const email = useSelector( state => getEmail(state) );
	const listUserData = useSelector(state => getUserListData (state));
	const userFound = listUserData?.records?.find(item => item?.email == email);
    const servicesData = useSelector( state => getServicesByExerciseIdData(state) );
	const servicesDataIsFetching = useSelector( state => getServicesByExerciseIdIsFetching(state) );
    const hideIcon = userFound?.profileName === "Responsable de transferencia de fondos" ? true : false;
	const administrativeService = useSelector(state => getAdministrativeServiceListAllData(state))?.records?.sort((a, b) => a.code > b.code ? 1 : -1);
	const adminServicesFiltered = administrativeService?.sort((a, b) => a.code > b.code ? 1 : -1)
        ?.map(item => ({
            id: item?.id,
            shortName: item?.shortName,
            code: item?.code,
        })
    )
    let defaultParams = {
        page: 0,
        size: 10,
        sort: 'id,desc',
        administrativeServiceId: !hideIcon ? listIdsServiceAdministrative : '',
        year: selectedPeriod?.year,
    }
    const administrativeServiceData = !hideIcon ? AdministrativeServiceData() : adminServicesFiltered;
    const initialServiceId = !hideIcon ? administrativeServiceData[0]?.code : '';
    const [serviceId, setServiceId] = useState(listIdsServiceAdministrative);

    const handleAdministrativeService = value => {
        const serviceValue = value != '' ? value : defaultParams?.administrativeServiceId;
        const yearToSend = filterObject?.year ? filterObject?.year : selectedPeriod?.year;
        setServiceId(serviceValue);
        newFilterObject = {
            ...filterObject,
            year: yearToSend,
            administrativeServiceId: serviceValue,
        };
        fetchList(newFilterObject);
    };

    useEffect(() => {
		dispatch(tryListAllAdministrativeServices());
        dispatch(tryListAllServiceAdministrativeByUser(userFound?.id));
        dispatch(tryGetTypeListFundRequests());
        dispatch(tryGetStateListFundRequests());
        listIdsServiceAdministrative && loadAllData();
    }, [listIdsServiceAdministrative]);
    
    const loadAllData = () => {
        dispatch(clearListFundRequestsData());
        fetchList(defaultParams);
    }

    const onPageChange = (pNumber) => {
        const yearToSend = filterObject?.year ? filterObject?.year : defaultParams?.year;
        const params = {
            ...filterObject,
            ...defaultParams,
            administrativeServiceId: serviceId,
            page: pNumber - 1,
            year: yearToSend
        }
        fetchList(params);
    }

    const clearFilters = () => {
        setFilterObject({});
        reset();
        loadAllData();
        setServiceId(listIdsServiceAdministrative);
    };

    const fetchList = (params) => {
        dispatch(tryListFundRequests(params))
    };

    const onSubmitForm = (data) => {
        const yearToSend = data?.year ? data?.year : defaultParams?.year; 
        if (!fundRequestsListIsFetching) {
            cancelSource.cancel();
            cancelSource = CancelToken.source();
            newFilterObject = {
                ...data,
                ...defaultParams,
                administrativeServiceId: serviceId,
                year: yearToSend      
            };
            newFilterObject = getClearObject(newFilterObject);
            setFilterObject(newFilterObject);
            fetchList(newFilterObject);
        }
    };

    const onClickDetail = fundRequests => {
        dispatch(setFundRequestsToDetail(fundRequests));
        dispatch(push(FUND_REQUESTS_DETAILS));
    };

    const handleDownload = (id) => {
        dispatch(tryGetReportFundRequestById(id));
    };

    const onClickEdit = fundRequests => {
        dispatch(setFundRequestsToEdit(fundRequests));
        dispatch(push(FUND_REQUESTS_EDIT));
    };

    const onClickNew = () => {
        dispatch(push(FUND_REQUESTS_NEW));
    };

    const onClickDelete = fundRequests => {
        let nameFundRequests = ' Nro.: ' + fundRequests?.number + '/' + fundRequests?.year;
        swal({
            title: 'Confirmación',
            text: '¿' + questionAnnulConfirm + nameFundRequests + '?',
            icon: 'warning',
            buttons: ["Cancelar", "Aceptar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    dispatch(tryDeleteFundRequests(fundRequests?.id)).then(
                        response => {
                            if (!(response?.data?.hasError)) {
                                loadAllData();
                            }
                        }
                    );
                }
            });
    };

    const validateActionIcon = (item, type) => {
        let actionIconItem = {
            text: '',
            validation: false
        };
        if(type === VALIDATE_ACTION_TYPE.DELETE) {
            if(item?.state === 'AUTORIZADO_PENDIENTE_DE_PAGO') {
                actionIconItem.text = "Pedido de Fondo pendiente de aprobación";
            } else if (administrativeServiceClose(servicesData, item?.administrativeServiceId)) { 
                actionIconItem.text = "No puede anular una Pedido de Fondo con Servicio cerrado dentro del ejercicio";
            } else {
                actionIconItem.text = "Anular Pedido de Fondo", 
                actionIconItem.validation = true
            }
        };
        if(type === VALIDATE_ACTION_TYPE.UPDATE) {
            if(item?.state === 'AUTORIZADO_PENDIENTE_DE_PAGO') {
                actionIconItem.text = "Pedido de Fondo pendiente de aprobación";
            } else if (administrativeServiceClose(servicesData, item?.administrativeServiceId)) {
                actionIconItem.text = "No puede editar un Pedido de Fondo con Servicio cerrado dentro del ejercicio";
            } else {
                actionIconItem.text = "Editar Pedido de Fondo", 
                actionIconItem.validation = true
            }
        };
        return actionIconItem
    };

    return <>

            <Container fluid>
                <Card className='mb-5'>
                    <Card.Header className='d-flex justify-content-between'>
                        <h1 className="h6 mt-1 mb-0">Pedido de Fondos</h1>
                        <a className='text-white' target="_blank" href='https://dev.kb.cgmisiones.gob.ar/docs/safi2/safi2-operador-tesoreria/#ejecuci%C3%B3n-financiera--pedido-de-fondos'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-2' />
                            <small>Ver manual de uso</small> 
                        </a>
                    </Card.Header>
                    <Card.Body>
                        <div className='d-flex'>
                            {fundRequestsPermissions?.canCreate &&
                                <Button size='md' className='mb-3' variant='success' onClick={onClickNew}>
                                    <FontAwesomeIcon icon={faPlus} className='mr-1' />
                                    Nuevo pedido de fondos
                                </Button>
                            }
                            {administrativeServiceData?.length > 1 &&
                                <select
                                    value={serviceId}
                                    className='form-control col-3 mx-3'
                                    onChange={(e) => handleAdministrativeService(e.target.value)}
                                >
                                    <option value={''}>Todos los servicios</option>
                                    {Array.isArray(administrativeServiceData) && 
                                        administrativeServiceData.map(item => (
                                            <option className='py-2' value={item.id} key={item.id}>
                                                {item.code} - {item.shortName}
                                            </option>
                                        ))
                                    }
                                </select>
                            }
                        </div>
                        <Form onSubmit={handleSubmit(onSubmitForm)} autoComplete='off'>
                        <Table striped hover size='sm' >
                            <thead>
                                <tr ref={creditLabelsRefContainer}>
                                    <th className='text-center align-middle' width='10%'>Tipo Pedido de Fondos</th>
                                    <th className='text-center align-middle' width='10%'>Número / Año</th>
                                    <th className='text-center align-middle' width='5%'>Fecha</th>
                                    <th className='text-center align-middle' width='10%'>Expedientes</th>
                                    <th className='text-center align-middle' width='20%'>Nombre Cuenta Bancaria</th>
                                    <th className='text-center align-middle' width='20%'>Descripción</th>
                                    <th className='text-center align-middle' width='5%'>Importe</th>
                                    <th className='text-center align-middle' width='10%'>Estado</th>
                                    <th className='text-center align-middle' width='10%'>Acciones</th>
                                </tr>
                                <tr className='secondary'>
                                    <th className='text-center align-middle'>
                                        <Form.Control
											as="select"
											name="fundRequestType"
											ref={register}
                                            className={"text-black-color"}
                                            size='sm'
										>
											<option value={''}>Seleccione una opcion...</option>
											{
												fundFormsReadyToDisplay(fundRequestTypes)?.map((item, idx) => (
													<option value={item} key={idx}>
														{getFundRequestTypeValueToShow(item)}
													</option>
												))
											}
										</Form.Control>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <div className='input-group'>
                                            <Form.Control placeholder="Nro" className='text-center' size='sm' name='number' type='number' ref={register}/>
                                            <Form.Control placeholder="Año" className='text-center' size='sm' name='year' type='number' ref={register}/>
                                        </div>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control className='text-center' size='sm' name='fundRequestDate' type='date' ref={register}/>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control className='text-center' size='sm' name='paymentOrderAdministrativeDocuments' type='text' ref={register}/>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control className='text-center' size='sm' name='descriptionBankAccount' type='text' ref={register}/>
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control className='text-center' size='sm' name='description' type='text' ref={register}/>
                                    </th>
                                    <th className='text-center align-middle'>
                                        {/* <Form.Control className='text-center' size='sm' name='year' type='amount' ref={register}/> */}
                                    </th>
                                    <th className='text-center align-middle'>
                                        <Form.Control
                                                as="select"
                                                name="state"
                                                ref={register}
                                                className={"text-black-color"}
                                                size='sm'
                                            >
                                                <option value={''}>Seleccione una opcion...</option>
                                                {
                                                    stateFormsReadyToDisplay(fundRequestStates)?.map((item, idx) => (
                                                        <option value={item} key={idx}>
                                                           {getFundRequestStateValueToShow(item)}
                                                        </option>
                                                    ))
                                                }
                                        </Form.Control>
                                    </th>
                                    <th className='text-center align-middle'>
                                    <div className='d-flex justify-content-around'>
                                             <ActionIcon
                                                 size="lg"
                                                 id="search-button"
                                                 className="btn-primary search-button text-white-color"
                                                 toolTipText="Filtrar"
                                                 icon={faFilter}
                                                 type='submit'
                                                 onSubmit={onSubmitForm}
                                                />
                                             <ActionIcon
                                                 size="lg"
                                                 id="clean-filter"
                                                 className="btn-primary clean-filter text-white-color"
                                                 toolTipText="Limpiar filtros"
                                                 icon={faBroom}
                                                 type='reset'
                                                 onClick={()=> clearFilters()}
                                                />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-black-color'>
                            { (records?.length && !userAdmServIsFetching && listIdsServiceAdministrative) ?
                                            records.map((item) => (
                                                <tr key={item.id} className={item?.state === "ANULADO" ? 'text-danger' : ''}>
                                                    <td className='text-center align-middle'>
                                                        {getFundRequestTypeValueToShow(item?.fundRequestType)}
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        {item?.number + " - " + item?.year}
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        {dateNeutralFormatedToShowARG(item?.fundRequestDate)}
                                                    </td>
                                                    <td className='text-center align-middle maxTruncate'>
                                                        {item?.paymentOrderAdministrativeDocuments?.split(", ").join("\n\n")}
                                                    </td>
                                                    <td className='text-center align-middle truncate maxTruncate'>
                                                        {item?.bankAccount?.description}
                                                    </td>
                                                    <td className='text-center align-middle maxTruncate'>
                                                        {item?.description}
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        {formatterPeso.format(item?.amount)}
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        {item?.label}
                                                    </td>
                                                    <td className='text-center pm-0 m-0 align-middle table-responsive btn-group' >
                                                        { (item?.state !== "ANULADO") ?
                                                            <>
                                                                { fundRequestsPermissions?.canView &&
                                                                    <ActionIcon 
                                                                        size='lg' 
                                                                        id='credit-query' 
                                                                        className='mx-2'
                                                                        toolTipText='Detalle del Pedido de Fondo' 
                                                                        icon={faSearch} 
                                                                        onClick={() => onClickDetail(item?.id)} 
                                                                    />
                                                                }
                                                                { fundRequestsPermissions?.canView &&
                                                                    <ActionIcon 
                                                                        size='lg' 
                                                                        id='credit-query' 
                                                                        className='mx-2'
                                                                        toolTipText='Descargar Pedido de Fondo' 
                                                                        icon={faDownload} 
                                                                        onClick={() => handleDownload(item?.id)} 
                                                                    />
                                                                }
                                                                { (fundRequestsPermissions?.canUpdate && !hideIcon) &&
                                                                    <ActionIcon
                                                                        size='lg'
                                                                        id='credit-query'
                                                                        className={((item?.state === 'AUTORIZADO_PENDIENTE_DE_PAGO' || administrativeServiceClose(servicesData, item?.administrativeServiceId)) ? 'not-allowed' : '') + ' mx-2 '}
                                                                        toolTipText={validateActionIcon(item, VALIDATE_ACTION_TYPE.UPDATE).text} 
                                                                        onClick={() => validateActionIcon(item, VALIDATE_ACTION_TYPE.UPDATE).validation && onClickEdit(item)}
                                                                        icon={faEdit} 
                                                                    />
                                                                }
                                                                { (fundRequestsPermissions?.canDelete && !hideIcon) &&
                                                                    <ActionIcon
                                                                        size='lg'
                                                                        id='delete'
                                                                        className={((item?.state === 'AUTORIZADO_PENDIENTE_DE_PAGO' || administrativeServiceClose(servicesData, item?.administrativeServiceId)) ? 'not-allowed' : '') + ' mx-2 '}
                                                                        toolTipText={validateActionIcon(item, VALIDATE_ACTION_TYPE.DELETE).text} 
                                                                        onClick={() => validateActionIcon(item, VALIDATE_ACTION_TYPE.DELETE).validation && onClickDelete(item)}
                                                                        icon={faTrash} 
                                                                    />
                                                                }
                                                            </> : 
                                                            <>
                                                                { (fundRequestsPermissions?.canView & item?.state === 'ANULADO') &&
                                                                    <ActionIcon 
                                                                        size='lg' 
                                                                        id='credit-query' 
                                                                        className='mx-2'
                                                                        toolTipText='Detalle del Pedido de Fondo anulado' 
                                                                        icon={faSearch} 
                                                                        onClick={() => onClickDetail(item?.id)} 
                                                                    />
                                                                }
                                                            </> 
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        :
                                        <tr>
                                            <td colSpan={colSpanCellsNumber} className='text-center'>
                                                {
                                                    !fundRequestsListIsFetching && listIdsServiceAdministrative &&
                                                        <Alert variant='info' className='mb-0'>
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className='text-black-color mr-3' />
                                                        No hay registros
                                                        </Alert>
                                                }
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                        </Form>
                        <RCPagination
                            activePage={pageNumber + 1}
                            itemsCountPerPage={defaultParams.size}
                            totalItemsCount={totalCount}
                            totalItems={totalCount}
                            thePage={pageNumber + 1}
                            onChange={onPageChange}
                            innerClass="justify-content-center"
                            />                
                    </Card.Body>
                </Card>
            </Container>
        <AppLoading isLoading={fundRequestsListIsFetching|| reportIsFetching || userAdmServIsFetching || servicesDataIsFetching} />
    </>;

}
export default FundRequestsListPage; 